import React, { useState } from "react"
import getStripe from "../../utils/stripejs"

const formatPrice = (amount, currency) => {
  let price = (amount / 100).toFixed(2)
  let numberFormat = new Intl.NumberFormat(["en-US"], {
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol",
  })
  return numberFormat.format(price)
}

const ProductCard = ({ product }) => {
  const [loading, setLoading] = useState(false)

  const handleSubmit = async event => {
    event.preventDefault()
    setLoading(true)

    const price = new FormData(event.target).get("priceSelect")
    const stripe = await getStripe()
    const { error } = await stripe.redirectToCheckout({
      mode: "payment",
      lineItems: [{ price, quantity: 1 }],
      successUrl: `${window.location.origin}/payment-success/`,
      cancelUrl: `${window.location.origin}/payment/`,
    })

    if (error) {
      console.warn("Error:", error)
      setLoading(false)
    }
  }

  return (
    <div className="border border-green p-4">
      <p className="italic">Select payment amount</p>
      <form onSubmit={handleSubmit}>
        <fieldset style={{ border: "none" }} className="my-3">
          <label>
            <select name="priceSelect" className="focus:outline-none">
              {product.prices.map(price => (
                <option key={price.id} value={price.id}>
                  {formatPrice(price.unit_amount, price.currency)}
                </option>
              ))}
            </select>
          </label>
        </fieldset>
        <button
          disabled={loading}
          className="border border-green py-2 px-4 hover:underline"
        >
          Checkout
        </button>
      </form>
    </div>
  )
}

export default ProductCard
