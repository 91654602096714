import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Products from "../components/products/products"

const PaymentPage = () => (
  <Layout>
    <SEO title="Payment" />
    <div className="max-w-4xl mx-auto w-full text-center px-4">
      <h1 className="mt-8">Payments</h1>
      <div className="my-10">
        <Products />
      </div>
    </div>
  </Layout>
)

export default PaymentPage
